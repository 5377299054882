import { setgroups } from 'process';
export default {
  setup: function setup() {
    var star = require('assets/imgs/star.png');

    var star1 = require('assets/imgs/star1.png');

    var star2 = require('assets/imgs/star2.png');

    var star3 = require('assets/imgs/star3.png');

    var star4 = require('assets/imgs/star4.png');

    var starList = [{
      img: star1,
      name: '快速'
    }, {
      img: star2,
      name: '便捷'
    }, {
      img: star3,
      name: '品质'
    }, {
      img: star4,
      name: '省心'
    }];
    return {
      star: star,
      starList: starList
    };
  }
};