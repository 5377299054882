export default {
  setup: function setup() {
    var img1 = require('assets/imgs/c1.png');

    var img2 = require('assets/imgs/c2.png');

    var img3 = require('assets/imgs/c3.png');

    var list = [{
      img: img1,
      title: '559VIP会员',
      info: ['终身VIP车检服务', '送199元专属礼品', '400元商城抵扣券'],
      state: '立即购买'
    }, {
      img: img2,
      title: '预约有折扣车检更无忧',
      info: ['前3个月预约车检7折', '前2个月预约车检8折', '前1个月预约车检9折'],
      state: '立即预约'
    }, {
      img: img3,
      title: '集团大客户VIP通道',
      info: ['物业公司', '网约车', '二手车、4s店等', '集团客户'],
      state: '立即进入'
    }];

    var showtoast = function showtoast() {
      ElMessageBox.alert('敬请期待！', '提示', {
        confirmButtonText: 'OK',
        callback: function callback(action) {}
      });
    };

    return {
      list: list,
      showtoast: showtoast
    };
  }
};