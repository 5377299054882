import Search from 'components/Search';
import Star from 'components/Home/Star';
import CharacteristicV from 'components/Home/Characteristic';
import Reserve from '../components/Home/Reserve';
import StaticPage from '../components/Home/StaticPage';
import { useRouter } from 'vue-router';
export default {
  components: {
    Search: Search,
    Star: Star,
    CharacteristicV: CharacteristicV,
    Reserve: Reserve,
    StaticPage: StaticPage
  },
  setup: function setup() {
    var banner = require('assets/imgs/banner.png');

    var router = useRouter();

    var toPage = function toPage() {
      router.push({
        path: '/subscribe'
      });
    };

    return {
      banner: banner,
      toPage: toPage
    };
  }
};