export default {
  setup: function setup() {
    var backImg = require('assets/imgs/sky.png');

    var list = [{
      title: '最近的车检服务站',
      lightHeigh: '全国10000+个车辆检测站',
      subTitle: '就近选择'
    }, {
      title: '最优惠的价格活动',
      lightHeigh: '超35000种低价优惠',
      subTitle: '超值优惠 & 100%低价'
    }, {
      title: '最快的车检时间',
      lightHeigh: '28分钟快检',
      subTitle: '预定节约时间高达48%'
    }];
    return {
      backImg: backImg,
      list: list
    };
  }
};