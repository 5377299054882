import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5a39ebbe"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "chara"
};
var _hoisted_2 = {
  class: "info-box"
};
var _hoisted_3 = {
  class: "item"
};
var _hoisted_4 = {
  class: "top"
};
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  class: "text"
};
var _hoisted_7 = {
  class: "title"
};
var _hoisted_8 = {
  class: "subtitle"
};
var _hoisted_9 = {
  class: "sn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, function (item, index) {
    return _openBlock(), _createElementBlock("li", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
      src: item.img,
      alt: ""
    }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(item.title), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.info, function (subItem, subIndex) {
      return _openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("span", _hoisted_9, _toDisplayString(subIndex + 1), 1), _createTextVNode(" " + _toDisplayString(subItem), 1)]);
    }), 256))])]), _createElementVNode("div", {
      class: "state pointer",
      onClick: _cache[0] || (_cache[0] = function () {
        return $setup.showtoast && $setup.showtoast.apply($setup, arguments);
      })
    }, _toDisplayString(item.state), 1)]);
  }), 256))])]);
}